<template>
  <div class="about" v-loading="loading">
    <div>
      <el-select style="margin: 0 10px 0 0" clearable @change="serchfane()" size="mini" v-model="query_time" placeholder="请选择">
        <el-option label="今日" value="今日"></el-option>
        <el-option label="昨日" value="昨日"></el-option>
        <el-option label="本周" value="本周"></el-option>
        <el-option label="上周" value="上周"></el-option>
        <el-option label="本月" value="本月"></el-option>
        <el-option label="上月" value="上月"></el-option>
        <el-option label="本季度" value="本季度"></el-option>
        <el-option label="上季度" value="上季度"></el-option>
        <el-option label="今年" value="今年"></el-option>
        <el-option label="去年" value="去年"></el-option>
      </el-select>
      <el-date-picker
          style="margin: 0 10px 0 0"
          :disabled="query_time!=''"
          size="mini"
          v-model="date_time"
          type="daterange"
          value-format="YYYY-MM-DD"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
      </el-date-picker>
      <el-button size="mini" @click="onLoad()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table show-summary
              border
              highlight-current-row
              height="calc(100vh - 230px)"
              :data="tableData"
              style="width: 100%;">
      <el-table-column width="65" align="center" type="index" label="#" >
      </el-table-column>

      <el-table-column align="center"  prop="" label="广告牌名称" >
        <template #default="scope">
          <span style="color: #00a2d4;font-weight: 600;cursor:pointer" @click="dowTJ(scope.row)">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column  align="center" prop="customer_count" label="客户端数量" >
      </el-table-column>
      <el-table-column align="center" prop="worker_count" label="师傅端数量" >
      </el-table-column>
      <el-table-column align="center" prop="total_count" label="总数" >
      </el-table-column>
    </el-table>
    <el-dialog
        :title="rowData.title"
        v-model="drawer"
        top="75px"
        width="1024px"
        :close-on-click-modal="false"
        destroy-on-close>
      <ADV :nowData="rowData"></ADV>
    </el-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/Api"//本页面接口
import ADV from './advsticChild'
export default {
  name: 'brand',
  components:{
    ADV
  },
  data(){
    return{
      rowData:{
        adv_name:'',
      },
      login:false,
      drawer:false,
      tableData:[],
      loading:false,

      query_time:'本月',
      is_quit:0,
      date_time:[],

      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        platform:0,
        store:0,
        version_code:'',
        version_name:'',
      },
      platformArr:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],
      storeArr:[
        {
          id:0,
          name:'ios',
        },
        {
          id:1,
          name:'安卓',
        },
      ],
      rules:{
        platform: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        store: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        version_code: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        version_name: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onLoad()
    this.keyupSubmit()
  },

  methods:{
    dowTJ(row){
      this.rowData.title=row.name
      this.rowData.adv_name=row.id
      this.drawer=true
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.onLoad()
        }
      }
    },
    serchfane(){
      this.date_time=[]
    },
    // onTable(){
    //   let form={
    //     page:this.page,
    //     limit:this.limit,
    //   }
    //   axios.get(form,'/api/admin/rankingList').then((res)=>{
    //     console.log(res)
    //     this.tableData=res.data.data
    //     console.log('this.tableData',this.tableData)
    //     this.total=res.data.total
    //   }).catch((err)=>{
    //
    //   })
    // },
    onLoad(){
      let form={
        is_quit:this.is_quit,
        date_time:this.date_time,
        query_time:this.query_time,
      }
      this.loading=true
      console.log(form)
      axios.post(form,'/api/admin/advAllSort').then((res)=>{
        console.log(res)
        if(res.code==200){
          this.tableData=res.data.data
          this.loading=false
        }else {
          this.$message.error(res.msg)
          this.loading=false
        }
      }).catch((err)=>{
        this.$message.error('请求失败，联系管理员！')
        this.loading=false
      })
    },
    search(){
      this.onTable()
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`)
    //   this.limit=val
    //   this.onTable()
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`)
    //   this.page=val
    //   this.onTable()
    // },


  }
}
</script>
<style lang="less" >
.danger{
  text-decoration:line-through;color: #f18b8b
}
.wing{
  text-decoration:line-through;color: #f3b681
}
.tang{
  text-decoration:line-through;color: #8ba8f1
}
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>